import React from "react"
import Layout from "../components/Layout"
import { Router } from "@reach/router"
import Okr from "../components/Okr"

const Index = () => (
  <Layout>
    <Router>
      <Okr path="/okr/:splat" />
    </Router>
  </Layout>
)

export default Index
